import { NavLink, useParams } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { Alert, Autocomplete, Button, Divider, Grid, TextField, useTheme, Chip, Typography, useMediaQuery, Icon } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../Infrastructure/Store/store";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { DefaultResponse } from "../../../../Infrastructure/Api/Model/DefaultResponse";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EmptyCustomerForm } from "../AllCustomers/Models/CustomerDetailForm";
import { useCustomerDetailFormValidator } from "../AllCustomers/Models/useCustomerDetailFormValidator";
import { CustomBox } from "../../../../Layout/CustomBox";
import { TextFieldFormControl } from "../../../../Layout/Inputs/TextFieldFormControl";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerDetailResponseDto } from "./Models/CustomerDetailResponseDto";
import { StyledPopper, ListboxComponent } from "../../../../Layout/Inputs/AutoCompleteVirtualizationHelper";
import { HeroTopBanner } from "../../../../Layout/HeroTopBanner/HeroTopBanner";
import { CustomStatusCard } from "../../../../Layout/CustomImage/CustomStatusCard";
import { PiUsersLight, PiCrown, PiCrownFill } from "react-icons/pi";
import { CustomerEditRequestDto } from "./Models/CustomerEditRequestDto";
import { CustomerEditLicencesDrawer } from "./Components/CustomerEditLicencesDrawer";
import { CustomerEditLicencesResponseDto } from "./Models/CustomerLicencesConsent";


export const CustomerEdit = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const theme = useTheme();
  const language = useAppSelector(store => store.settings.language);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [availableCountries, setAvailableCountries] = useState<{ Id: number, CountryName: string }[]>();
  const [countryError, setCountryError] = useState<boolean>(false);
  const {CustomerDetailFormValidator} = useCustomerDetailFormValidator();

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["customersEdit"],
    queryFn: async () => await fetchMyDepragApi<CustomerDetailResponseDto>(
      { customerId: parseInt(id!), preferredLanguageIso: language },
      "customers/get",
      "POST",
      dispatch,
      store.getState() as RootState),
    enabled: id !== undefined,
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: EmptyCustomerForm,
    values: data ? {
      companyName: data.CompanyName,
      sapCustomerId: data.SapCustomerId ? data.SapCustomerId : "",
      internalId: data.InternalId ? data.InternalId : "",
      externalTicketSystemId: data.ExternalTicketSystemId ? data.ExternalTicketSystemId : "",
      city: data.City ? data.City : "",
    } : EmptyCustomerForm,
    resolver: zodResolver(CustomerDetailFormValidator),
  });

  const onSubmit = async (formData: any) => {
    if (selectedCountry.trim().length === 0) {
      setCountryError(true);
    } else {
      if (id !== undefined) {
        var dataToPost: CustomerEditRequestDto = {
          CustomerId: parseInt(id),
          CompanyName: formData.companyName,
          SapCustomerId: formData.sapCustomerId,
          InternalId: formData.internalId,
          CountryId: availableCountries?.filter(x => x.CountryName === selectedCountry)[0].Id || null,          
        };
        await fetchMyDepragApi<DefaultResponse>(dataToPost, "customers", "POST", dispatch, store.getState() as RootState, "SuccessfullyChangedCustomer");
      }
    }
  };

  useEffect(() => {
    if (data && data.Countries && data.Countries.length > 0) {
      setAvailableCountries(data.Countries);
      data.CountryId && setSelectedCountry(data.Countries.filter(x => x.Id === data.CountryId)[0].CountryName);
    }
  }, [data]);

  useEffect(() => {
    refetch()
  }, [id])

  const [sideDrawerLicencesOpen, setSideDrawerLicencesOpen] = useState<boolean>(false);
  

  const closeSideDrawerLicences = () => {
    setSideDrawerLicencesOpen(false);
  }

  const openLicencesDrawer = () => {
    setSideDrawerLicencesOpen(true);
  }

  function GetLicences() {
    const { isLoading, data } = useQuery({
        queryKey: ["customerlicences"],
        queryFn: async () => await fetchMyDepragApi<CustomerEditLicencesResponseDto[]>({},
            'customers/licences/' + id + "/" + language,
            'GET',
            dispatch,
            store.getState() as RootState),
        enabled: id !== undefined
    });

    return {
        Data: data,
        IsLoading: isLoading
    }
}

const licences = GetLicences()


  return (
    <LanguageConsumer>
      {({ getTranslatedText }) =>
        <>
          <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
            <Grid item xs={12} mx={3}>
              <Grid container direction={"row"} spacing={1}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Button color="darky" component={NavLink} to={"/customers"}
                    variant="outlined">{getTranslatedText("Prev")}</Button>
                </Grid>

                <Grid item xs={12}>
                  <HeroTopBanner bgImageUrl={""}
                    sx={{ mx: 0 }}
                    minHeight={140}
                    headLine={<Typography variant="h3"
                      fontFamily={"Fira Sans"}
                      fontWeight={700}
                      color="success">
                      {data?.CompanyName}
                    </Typography>}
                    headSubLine={<>
                      {(data && data.AvailableGoldLicences > 0) && <Chip
                        sx={{
                          color: "black",
                          background: "linear-gradient(57deg, rgba(255,240,189,1) 0%, rgba(255,214,107,1) 32%, rgba(255,234,196,1) 50%, rgba(255,214,107,1) 70%, rgba(255,240,189,1) 100%)",
                        }}
                        label={`${getTranslatedText("GoldLicensesInUse")}: ${data.GoldLicencesInUse} / ${data.AvailableGoldLicences}`} />}
                      {(data && data.LastUpdate != null) &&

                        <Chip
                          label={`${getTranslatedText("LastUpdate")}: ${new Date(data.LastUpdate).toLocaleString("de")}`} />}

                    </>}
                    hasNoBackgroundImage={true}
                    hasHeadSubLine={true} />
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                  <CustomBox elevation={0} sx={{
                    backgroundSize: "cover",
                    backgroundBlendMode: "multiply",
                    backgroundColor: "transparent",
                    border: `1px solid ${theme.palette.cardBorder.main}`,
                    marginBottom: 1
                  }}>


                    {data?.HeaderText && <Grid item xs={12} sx={{ textAlign: "left" }}>
                      <Alert severity="warning">{data?.HeaderText}</Alert>
                      <Divider sx={{ my: 3, mb: 2 }} />
                    </Grid>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {data && data.IsExclusiveCreatedBySap &&

                        <Grid item xs={12} sx={{ textAlign: "left" }}>
                          <Alert severity="warning">{getTranslatedText("CustomerWasCreatedBySAP")}</Alert>

                          <Divider sx={{ my: 3, mb: 2 }} />
                        </Grid>
                      }
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldFormControl
                            inputProps={{ "data-testid": "customerDetail-CompanyName" }}
                            disabled={isLoading || !data || data.IsExclusiveCreatedBySap}
                            hasEndAdornment={false}
                            labelText={getTranslatedText("CustomerCompanyName")}
                            propertyName="companyName"
                            register={register} registerOptions={{ valueAsNumber: false }} errors={errors}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldFormControl
                            inputProps={{ "data-testid": "customerDetail-InternalId" }}
                            disabled={isLoading || !data || data.IsExclusiveCreatedBySap}
                            hasEndAdornment={false}
                            labelText={getTranslatedText("CustomerInternalId")}
                            propertyName="internalId"
                            register={register} registerOptions={{ valueAsNumber: false }} errors={errors}
                            maxLength={10}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldFormControl
                            inputProps={{ "data-testid": "customerDetail-SapCustomerId" }}
                            disabled={true}
                            hasEndAdornment={false}
                            labelText={getTranslatedText("CustomerSapCustomerId")}
                            propertyName="sapCustomerId"
                            register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldFormControl
                            disabled={true}
                            hasEndAdornment={false}
                            labelText={getTranslatedText("ExternalTicketSystemId")}
                            propertyName="externalTicketSystemId"
                            register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                        </Grid>

                        <Grid item xs={12}>
                          {
                            isLoading || !data || data.IsExclusiveCreatedBySap ?
                              <TextField
                                value={selectedCountry}
                                fullWidth
                                disabled
                                label={getTranslatedText("RegistrationCountry")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              :
                              <Autocomplete
                                disableListWrap
                                PopperComponent={StyledPopper}
                                ListboxComponent={ListboxComponent}
                                renderOption={(props, option, state) =>
                                  [props, option, state.index] as React.ReactNode
                                }
                                noOptionsText={getTranslatedText("NoCountriesAvailable")}
                                fullWidth
                                disablePortal
                                disabled={isLoading || !data || data.IsExclusiveCreatedBySap}
                                id="country"
                                options={availableCountries ? availableCountries.map(option => option.CountryName) : []}
                                value={selectedCountry}
                                ListboxProps={{
                                  style: { zIndex: 10002 },
                                }}
                                onChange={(event: any, newValue: string | null) => {
                                  if (newValue) {
                                    setCountryError(false);
                                    setSelectedCountry(newValue);
                                  }
                                }}
                                renderInput={(params) => <TextField
                                  {...params}
                                  fullWidth
                                  disabled={isLoading || !data || data.IsExclusiveCreatedBySap}
                                  error={countryError}
                                  helperText={countryError ? getTranslatedText("PleaseProvideACountry") : ""}
                                  label={getTranslatedText("RegistrationCountry")}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />}
                              />
                          }
                        </Grid>                       

                        <Grid item xs={12} sx={{ mt: 1 }}>
                          <Grid container direction={"row"} spacing={2}>
                            <Grid item xs={12} md={4}>
                              <CustomStatusCard src={""} blurIntensity={3}
                                hasOverlay
                                hasNoHoverEffect
                                centerOverlayText
                                overlayTitle={getTranslatedText("CountUsers")}
                                count={data && data.Id.toString() === id ? data.CountUsers : 0}
                                icon={<Icon sx={{ fontSize: { xs: 60, md: 80, lg: 120 }, mt: 1 }}><PiUsersLight /></Icon>}
                                iconSide={"left"}
                                borderCustomRadius={[5, 5, 5, 5]}
                                style={{ width: "100%" }}
                                gradientColor={"#f5f5f5"}
                                height={200} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <CustomStatusCard src={""} blurIntensity={3}
                                hasOverlay
                                hasNoHoverEffect
                                centerOverlayText
                                overlayTitle={getTranslatedText("GoldLicencesInUse")}
                                count={data && data.Id.toString() === id ? data.GoldLicencesInUse : 0}
                                icon={<Icon sx={{ fontSize: { xs: 60, md: 80, lg: 120 }, mt: 1 }}><PiCrownFill /></Icon>}
                                iconSide={"left"}
                                borderCustomRadius={[5, 5, 5, 5]}
                                style={{ width: "100%" }}
                                gradientColor={"#f5f5f5"}
                                height={200} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <CustomStatusCard src={""} blurIntensity={3}
                                hasOverlay
                                hasNoHoverEffect
                                centerOverlayText
                                overlayTitle={getTranslatedText("AvailableGoldLicences")}
                                count={data && data.Id.toString() === id ? data.AvailableGoldLicences : 0}
                                icon={<Icon sx={{ fontSize: { xs: 60, md: 80, lg: 120 }, mt: 1 }}><PiCrown /></Icon>}
                                iconSide={"left"}
                                borderCustomRadius={[5, 5, 5, 5]}
                                style={{ width: "100%" }}
                                gradientColor={"#f5f5f5"}
                                height={200} />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          {data && !data.IsExclusiveCreatedBySap &&
                            <Button color="success"
                              disabled={selectedCountry.trim().length === 0 || !!errors["sapCustomerId"] || !!errors["companyName"]}
                              variant="contained"
                              type="submit"
                              fullWidth>{getTranslatedText("Submit")}</Button>
                          }
                        </Grid>
                      </Grid>
                    </form>
                    
                    <Divider sx={{ my: 3, mb: 2 }} />
                        
                    <Grid item xs={12}>
                        <Button
                            color="darky"
                            variant="contained"
                            onClick={() => openLicencesDrawer()}>
                            {sideDrawerLicencesOpen ? getTranslation("HideLicencesDrawer") : getTranslation("ShowLicencesDrawer")}
                                                             
                        </Button>
                    </Grid>  
                  </CustomBox>

                  <CustomerEditLicencesDrawer open={sideDrawerLicencesOpen} onClose={closeSideDrawerLicences}
                                        licences={licences.Data ?? []} 
                                        />

                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </>
      }
    </LanguageConsumer>
  );
};

